
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/UI/Icone.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import { useAppBase } from '@/core/composables/AppBase';
import ServicoFormaPagamento from '@/servicos/ServicoFormaPagamento';
import { IFormaPagamentoParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IFormaPagamentoParametrosConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import { IDTOFormaPagamento } from '@/models/DTO/IDTOFormaPagamento';

export default defineComponent({
  name: 'FormaPagamentoListagem',
  components: {
    Icone,
    ComunicacaoApi,
  },
  setup() {
    const { appBase, apresentarComunicacaoApi, apresentarResposta } = useAppBase();
    const servicoFormaPagamento = new ServicoFormaPagamento();

    const state = reactive({
      formasPagamentos: [] as IDTOFormaPagamento[],
      parametrosConsulta: {} as IFormaPagamentoParametrosConsulta,
      apresentarFiltros: false,
      ordenarPor: 'CodigoFormaPagamentoDESC',
      opcoesOrdenacao: [] as IOptionString[],
    });
    let debounce = 0;

    state.opcoesOrdenacao = [];
    const ordemMaisRecentes:IOptionString = { value: 'CodigoFormaPagamentoDESC', label: 'Mais recentes' } as IOptionString;
    const ordemNomeAsc:IOptionString = { value: 'NomeFormaPagamentoASC', label: 'Nome (A-Z)' } as IOptionString;
    const ordemNomeDesc:IOptionString = { value: 'NomeFormaPagamentoDESC', label: 'Nome (Z-A)' } as IOptionString;
    state.opcoesOrdenacao.push(ordemMaisRecentes);
    state.opcoesOrdenacao.push(ordemNomeAsc);
    state.opcoesOrdenacao.push(ordemNomeDesc);

    async function buscarDados() {
      appBase.carregando = true;
      state.apresentarFiltros = false;
      state.formasPagamentos = [];
      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      if (appBase.paginacao.paginaAtual > 0) { state.parametrosConsulta.numeroPagina = appBase.paginacao.paginaAtual; }
      if (appBase.paginacao.qtdeRegistrosPagina > 0) { state.parametrosConsulta.qtdeRegistrosPagina = appBase.paginacao.qtdeRegistrosPagina; }
      state.parametrosConsulta.qtdeRegistrosTotal = 100;
      const respostaConsulta = await servicoFormaPagamento.obterFormaPagamentos(state.parametrosConsulta);
      if (UtilitarioGeral.validaLista(respostaConsulta.dados)) {
        state.formasPagamentos = respostaConsulta.dados;
        appBase.paginacao = respostaConsulta.paginacao;
      }

      appBase.carregando = false;
    }

    function buscarFormaPagamentoPorNome() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDados();
      }, 600);
    }
    async function limparFiltros() {
      state.parametrosConsulta.status = undefined;
      await buscarDados();
    }
    onBeforeMount(async () => {
      await buscarDados();
    });

    async function paginacaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = current;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }
    async function qtdeRegistrosPaginaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = 1;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }

    async function deletarFormaPagamento(codigo: number) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor...');
      appBase.resposta = await servicoFormaPagamento.deletar(codigo);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        const index = state.formasPagamentos.findIndex((u) => u.codigo === codigo);
        if (index >= 0) {
          state.formasPagamentos.splice(index, 1);
        }
      }
      apresentarResposta();
    }
    function confirmaExclusao(FormaPagamento:IDTOFormaPagamento) {
      Modal.confirm({
        title: 'Você confirma a exclusão da forma de pagamento:',
        content: `${FormaPagamento.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await deletarFormaPagamento(FormaPagamento.codigo); },
      });
    }
    return {
      appBase,
      state,
      UtilitarioMascara,
      buscarDados,
      limparFiltros,
      buscarFormaPagamentoPorNome,
      paginacaAlterada,
      qtdeRegistrosPaginaAlterada,
      confirmaExclusao,
    };
  },
});
