
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IFormaPagamento } from '@/models/Entidades/IFormaPagamento';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import ServicoFormaPagamento from '@/servicos/ServicoFormaPagamento';
import { IResposta } from '@/core/models/IResposta';
import { ETipoFormaPagamento } from '@/models/Enumeradores/ETipoFormaPagamento';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import ComboAplicacaoAcrescimoDesconto from '@/components/ComboAplicacaoAcrescimoDesconto.vue';
import ComboTipoIntegracaoPagamento from '@/components/FormasPagamentos/ComboTipoIntegracaoPagamento.vue';
import ComboResponsavelTaxaCartao from '@/components/FormasPagamentos/ComboResponsavelTaxaCartao.vue';
import { EAplicacaoAcrescimoDesconto } from '@/models/Enumeradores/EAplicacaoAcrescimoDesconto';
import { EResponsavelTaxasCartao } from '@/models/Enumeradores/EResponsavelTaxasCartao';
import { ETipoIntegracaoPagamento } from '@/models/Enumeradores/ETipoIntegracaoPagamento';

export default defineComponent({
  name: 'FormaPagamentoCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    CampoNumerico,
    ComboAplicacaoAcrescimoDesconto,
    ComboTipoIntegracaoPagamento,
    ComboResponsavelTaxaCartao,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoFormaPagamento = new ServicoFormaPagamento();

    const state = reactive({
      formaPagamento: {} as IFormaPagamento,
    });

    function limparDados() {
      state.formaPagamento = {} as IFormaPagamento;
      state.formaPagamento.codigo = 0;
      state.formaPagamento.ordem = 0;
      state.formaPagamento.tipo = ETipoFormaPagamento.Dinheiro;
      state.formaPagamento.status = true;
      state.formaPagamento.tipoIntegracao = ETipoIntegracaoPagamento.Nenhuma;
      state.formaPagamento.cartaoResponsavelTaxas = EResponsavelTaxasCartao.Comprador;
      state.formaPagamento.cartaoQuantidadeParcelas = 0;
      state.formaPagamento.boletoDiasVencimento = 0;
      state.formaPagamento.aplicacaoAcrescimo = EAplicacaoAcrescimoDesconto.Percentual;
      state.formaPagamento.valorAcrescimo = 0;
      state.formaPagamento.aplicacaoDesconto = EAplicacaoAcrescimoDesconto.Percentual;
      state.formaPagamento.valorDesconto = 0;
    }

    async function obterFormaPagamento(codigo:number) {
      appBase.carregando = true;
      state.formaPagamento = await servicoFormaPagamento.obter(codigo);
      appBase.carregando = false;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoFormaPagamento = obterCodigoRota();
      if (codigoFormaPagamento > 0) {
        await obterFormaPagamento(codigoFormaPagamento);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Forma de Pagamento.');

      if (state.formaPagamento.codigo === 0) {
        appBase.resposta = await servicoFormaPagamento.incluir(state.formaPagamento);
      } else {
        appBase.resposta = await servicoFormaPagamento.atualizar(state.formaPagamento);
      }

      apresentarRespostaRedirecionamento('FormasPagamentos');
    }

    return {
      appBase,
      state,
      salvar,
      ETipoFormaPagamento,
      ETipoIntegracaoPagamento,
      EAplicacaoAcrescimoDesconto,
    };
  },
});
